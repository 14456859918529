<template>
  <div v-if="paymentInfo">
    <div v-if="!message">
      <ul v-if="paymentType === $paymentConstants.TYPE_ATM">
        <li v-if="paymentInfo.bankCode">
          {{'ec.data.payment.info.bank_name'| t}}: {{paymentInfo.bankCode}}
        </li>
        <li v-if="paymentInfo.bankName">
          {{'ec.data.payment.info.bank_name'| t}}: {{paymentInfo.bankName}}
        </li>
        <li v-if="paymentInfo.account">
          {{'ec.data.payment.info.bank_name'| t}}: {{paymentInfo.account}}
        </li>
      </ul>

      <div v-if="isMaintainer && paymentType === $paymentConstants.TYPE_CVS_BARCODE">
        <div class="mb-2 text-center"><img id="barcode1"/></div>
        <div class="mb-2 text-center"><img id="barcode2"/></div>
        <div class="mb-2 text-center"><img id="barcode3"/></div>
      </div>

      <ul v-if="paymentType === $paymentConstants.TYPE_CVS_CODE">
        <li>
          {{'ec.data.payment.info.number'| t}}: {{paymentInfo.number}}
        </li>
      </ul>
    </div>

    <div v-if="message">
      <p v-html="message"></p>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
import jsBarcode from 'jsbarcode'
export default {
  mixins: [formItemMixin],
  props: {
    formKey: String,
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if(this.paymentType === this.$paymentConstants.TYPE_CVS_BARCODE) {
        this.renderBarcode()
      }
    },
    /**
     * js barcode文件: https://github.com/lindell/JsBarcode
     */
    async renderBarcode() {
      if(!this.isMaintainer) return
      await this.$nextTick()
      const option = {
        format: 'CODE39',
      }
      jsBarcode('img#barcode1', this.paymentInfo.barcode1, option)
      jsBarcode('img#barcode2', this.paymentInfo.barcode2, option)
      jsBarcode('img#barcode3', this.paymentInfo.barcode3, option)
    },
  },
  computed: {
    isMaintainer() {
      return this.$store.getters['token/isMaintainer']
    },
    message() {
      if(!this.paymentInfo.message) return null
      return this.nl2br(this.paymentInfo.message)
    },
    paymentInfo() {
      return this.storeData
    },
    paymentType() {
      return this.$eagleLodash.get(this.formData, 'payment.type')
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
